$breakpoints: (xs: 480px,
  sm: 575px,
  md: 768px,
  lg: 991px,
  xl: 1199px,
  xxl: 1599px,
);

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  }

  @else {
    @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
  }
}

@mixin mq($from: false, $until: false, $and: false, $media-type: "all") {
  $min-width: 0;
  $max-width: 0;
  $media-query: '';

  // From: this breakpoint (inclusive)
  @if $from {
    @if type-of($from)==number {
      $min-width: $from;
    }

    @else {
      $min-width: mq-get-breakpoint-width($from, $breakpoints);
    }
  }

  // Until: that breakpoint (exclusive)
  @if $until {
    @if type-of($until)==number {
      $max-width: $until;
    }

    @else {
      $max-width: mq-get-breakpoint-width($until, $breakpoints) - 1px;
    }
  }

  @if $min-width !=0 {
    $media-query: '#{$media-query} and (min-width: #{$min-width})';
  }

  @if $max-width !=0 {
    $media-query: '#{$media-query} and (max-width: #{$max-width})';
  }

  @if $and {
    $media-query: '#{$media-query} and #{$and}';
  }

  // Remove unnecessary media query prefix 'all and '
  @if ($media-type=='all'and $media-query !='') {
    $media-type: '';
    $media-query: str-slice(unquote($media-query), 6);
  }

  @media #{$media-query} {
    @content;
  }
}