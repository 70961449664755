$space: 24px;

$primaryBlue: #1a416c;
$primaryBlueLight: #bac6d2;
$primaryGreen: #009037;

$grey: (100: #ffffff,
  200: #fafafa,
  300: #f5f5f5,
  400: #e8e8e8,
  500: #d9d9d9,
  600: #bfbfbf,
  700: #8c8c8c,
  800: #595959,
  900: #262626,
  1000: #000000);

$primary: (100: #e6f4ff,
  200: #bae0ff,
  300: #91caff,
  400: #69b1ff,
  500: #4096ff,
  600: #1677ff,
  700: #0958d9,
  800:#003eb3,
  900: #002c8c,
  1000: #001d66);

$red: (100:#ffe6e6,
  200:#ffa6ac,
  300:#ff7d8a,
  400:#ff546b,
  500:#ff2b4f,
  600:#ff0235,
  700:#d90033,
  800:#ff2b4f,
  900:#ff0235,
  1000:#d90033);

$volcano: (100:#fff2e8,
  200:#ffd8bf,
  300:#ffbb96,
  400:#ff9c6e,
  500:#ff7a45,
  600:#fa541c,
  700:#d4380d,
  800:#ad2102,
  900:#871400,
  1000:#610b00);

$lime: (100: #fcffe6,
  200: #f4ffb8,
  300: #eaff8f,
  400: #d3f261,
  500: #bae637,
  600: #a0d911,
  700: #7cb305,
  800: #5b8c00,
  900: #3f6600,
  1000: #254000,
);

$green: (100: #f6ffed,
  200: #d9f7be,
  300: #b7eb8f,
  400: #95de64,
  500: #73d13d,
  600: #52c41a,
  700: #389e0d,
  800: #237804,
  900: #135200,
  1000: #092b00,
);

$cyan: (100: #e6fffb,
  200: #b5f5ec,
  300: #87e8de,
  400: #5cdbd3,
  500: #36cfc9,
  600: #13c2c2,
  700: #08979c,
  800: #006d75,
  900: #00474f,
  1000: #002329);

$geekblue: (100: #f0f5ff,
  200: #d6e4ff,
  300: #adc6ff,
  400: #85a5ff,
  500: #597ef7,
  600: #2f54eb,
  700: #1d39c4,
  800: #10239e,
  900: #061178,
  1000: #030852,
);

$yellow: (100: #feffe6,
  200: #ffffb8,
  300: #fffb8f,
  400: #fff566,
  500: #ffec3d,
  600: #fadb14,
  700: #d4b106,
  800: #ad8b00,
  900: #876800,
  1000: #614700,
);

$gold: (100: #fffbe6,
  200: #fff1b8,
  300: #ffe58f,
  400: #ffd666,
  500: #ffc53d,
  600: #faad14,
  700: #d48806,
  800: #ad6800,
  900: #874d00,
  1000: #613400,
);

$borderColor: #f0f0f0;