@import "../../styles/scss/mq";
@import "../../styles/scss/variables";


.Header {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid map-get($grey, 400);
  padding: $space / 2 $space;
  gap: $space / 2;

  @include mq($until: md) {
    flex-direction: column;
  }

  @include mq($from: md) {
    align-items: center;
  }
}

.Heading {
  display: flex;
  flex-direction: column;
  gap: $space / 2;
}

.Left {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  gap: $space / 2;

  @include mq($from: lg) {
    width: auto;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.Title {
  font-size: 18px !important;
  margin-block-start: 0;
  margin-bottom: 0 !important;

  @include mq($from: md) {
    font-size: 20px !important;
  }

  @include mq($from: lg) {
    font-size: 24px !important;
  }
}

.BreadcrumbLoading {
  height: 20px;
  line-height: 20px;
  width: 60px;
}

.SkeletonTitle {
  width: 320px !important;
}

.Back {
  border: none;
  background-color: map-get($grey, 300);
  color: map-get($grey, 900) !important;
  will-change: background-color color;
  transition: all 0.18s ease-in;

  &:hover,
  &:active,
  &:focus {
    background-color: $primaryBlue;
    color: $primaryGreen !important;
  }
}