@import "../../styles/scss/variables";

.Container {
  margin: 0 auto;
  width: 100%;
  padding: $space;

  &.XLarge {
    max-width: 1600px;
  }

  &.Large {
    max-width: 1300px;
  }

  &.Medium {
    max-width: 1120px;
  }

  &.Small {
    max-width: 960px;
  }

  &.Xsmall {
    max-width: 640px;
  }

  &.Full {
    margin: 0;
    max-width: 100%;
  }
}